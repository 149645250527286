.cv-list-wrapper {
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 20px;
}

.cv-list-wrapper a {
  color: #99a89c;
}

.cv-list-wrapper h1 {
  display: inline-block;
  margin-bottom: 18px;
}

.work-list a,
.work-list p {
  display: inline-block;
  margin: 10px 0;
}

a.github-link {
  float: right;
  margin-top: 32px;
}

.work-list {
  margin-top: 36px;
}

.work-item {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

@media (max-width: 900px) {
  .work-item {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: 42px 0;
  }
}